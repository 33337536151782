import React,{useEffect} from "react";
import {useOutletContext} from "react-router-dom";

const Home = ({name}) => {
    const {jwtToken}=useOutletContext();
    // useEffect(()=>{
    //     const headers = new Headers();
    //     headers.append("Content-Type", "application/json");
    //     headers.append("Authorization", "Bearer " + jwtToken);
    //
    //     const requestOptions = {
    //         method: "GET",
    //         headers: headers,
    //     };
    //     fetch(`${process.env.REACT_APP_BACKEND}/get-name`, requestOptions)
    //         .then(response=>response.json())
    //         .then(data=>console.log(data))
    //
    // })
    return (
        <>
            <div className="text-center">
                <h2>{}</h2>
                <hr />
            </div>
        </>
    );
};

export default Home;
