import React, { useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {useNavigate, useOutletContext, useParams} from "react-router-dom";

import {useScheduler} from "@aldabil/react-scheduler";
import { Table} from "react-bootstrap";
import Checkbox from "./form/Checkbox";
import Swal from "sweetalert2";


function MyModal({show, setShow,data}) {
    const {triggerDialog, setView} = useScheduler();
    const {id} = useParams();
    const {jwtToken} = useOutletContext()
    const [url, setUrl] = useState('');
    const [caption, setCaption] = useState('');
    const [category, setCategory] = useState('')
    const [type, setType] = useState();
    const [subs, setSubs] = useState();
    const [model, setModel] = useState({
        OnlyfansLink: "",
        bright_data_proxy_id: "",
        email: "",
        first_name: "",
        id: 0,
        image: "",
        last_name: "",
        subs: [],
        verified_subs: [],
        subs_array: [Array(1000).fill(false)],
    });
    const navigate = useNavigate()

    useEffect(() => {
        console.log(data)
        if (jwtToken === '') {
            navigate(`/login`)
        }
        //get events
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "Bearer " + jwtToken);

        const requestOptions = {
            method: "GET",
            headers: headers,
        }
        fetch(`${process.env.REACT_APP_BACKEND}/admin/model/${data.id}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                const checks = [];
                if (data.model.subs_array===null || data.model.subs_array===undefined){
                    data.model.subs_array=[]
                    const s = []
                }
                data.subreddits.forEach((g) => {
                    // console.log(g)
                    if (data.model.subs_array.indexOf(g.subreddit_id) !== -1) {
                        checks.push({ subreddit_id: g.subreddit_id, checked: true, name: g.name,ofLink: g.ofLink, verified: g.verified, category: g.category });
                    } else {
                        checks.push({ subreddit_id: g.subreddit_id, checked: false, name: g.name, ofLink: g.ofLink,verified: g.verified, category: g.category });
                    }
                });
                setModel({
                    ...data.model,
                    subs: checks,
                });
            })
            .catch((error) => {
                console.log(error)
            })
    }, [id, jwtToken,navigate, data])


    const handleClose = () => setShow(false);
    const handleUrl = (event) => {
        setUrl(event.target.value)
    }
    const handleCaption = (event) => {
        setCaption(event.target.value)
    }
    const handleType = (event) => {
        setType(event.target.value)
    }
    const [error, setError] = useState(null);
    const [errors, setErrors] = useState([]);

    const hasError = (key) => {
        return errors.indexOf(key) !== -1;
    };
    const handleSubmit = () => {
        console.log("Hi")
        let errors = [];
        if (model.subs_array.length === 0) {
            Swal.fire({
                title: "Error!",
                text: "You must choose at least one genre!",
                icon: "error",
                confirmButtonText: "OK",
            });
            console.log("error")
            errors.push("genres");
        }

        setErrors(errors);

        if (errors.length > 0) {
            return false;
        }

        // passed validation, so save changes
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "Bearer " + jwtToken);

        // assume adding versus updating
        let method = "PUT";

        if (model.id > 0) {
            method = "PATCH";
        }
        const requestBody = model;

        let requestOptions = {
            body: JSON.stringify(requestBody),
            method: method,
            headers: headers,
            credentials: "include",
        };

        fetch(`${process.env.REACT_APP_BACKEND}/admin/model/${data.id}/subreddits`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    console.log(data.error);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        setShow(false)
    };

    const handleCheck = (event, position) => {
        console.log("handleCheck called");
        console.log("value in handleCheck:", event.target.value);
        console.log("checked is", event.target.checked);
        console.log("position is", position);
        console.log(model)

        let tmpArr = model.subs;
        tmpArr[position].checked = !tmpArr[position].checked;

        let tmpIDs = model.subs_array;

        if (!event.target.checked) {
            tmpIDs.splice(tmpIDs.indexOf(event.target.value));
        } else {
            tmpIDs.push(parseInt(event.target.value, 10));
        }

        setModel({
            ...model,
            subs_array: tmpIDs,
        });
    };

    return (
        <>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"container"} style={{height: "60vh", overflow:"auto"}}>
                    <Table striped hover>
                        <thead>
                        <tr>
                            <th>Subreddit name</th>
                            <th>Subreddit Category</th>
                            <th>Subreddit Include Of Link</th>
                            <th>Subreddit Verified</th>
                            <th>Post here</th>
                        </tr>
                        </thead>
                        <tbody>
                        {model.subs && model.subs.length > 1 && (
                            <>
                            {Array.from(model.subs).map((g, index) => (
                                <tr>
                                    <td>{g.name}</td>
                                    <td>{g.category}</td>
                                    <td>{g.ofLink ? <>yes</>:<>no</>}</td>
                                    <td>{g.verified ? <>yes</>:<>no</>}</td>
                                    <td><Checkbox
                                        name={"subreddits"}
                                        key={index}
                                        id={"subreddit-" + index}
                                        onChange={(event) => handleCheck(event, index)}
                                        value={g.subreddit_id}
                                        checked={model.subs[index].checked}
                                    /></td>
                                </tr>
                            ))}
                            </>
                        )}
                        </tbody>


                    </Table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        Blast Me!
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default MyModal;

