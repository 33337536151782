import React, {useEffect, useState} from "react";
import {Link, useNavigate, useOutletContext} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import model from "./images/onlyfansmodel.jpeg"
import {Row,Col} from "react-bootstrap"
import Modal from "./Modal";
function Models(){
    const { jwtToken } = useOutletContext();
    const navigate = useNavigate();
    const [models,setModels]=useState([])
    const [show, setShow] = useState(false);
    const [model, setModel] = useState();
    function handleShow(m) {
        setModel(m)
        setShow(true);
    }
    useEffect( () => {
        if (jwtToken === "") {
            navigate("/login");
            return
        }
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "Bearer " + jwtToken);

        const requestOptions = {
            method: "GET",
            headers: headers,
        }

        fetch(`${process.env.REACT_APP_BACKEND}/admin/models`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setModels(data);
            })
            .catch(err => {
                console.log(err);
            })

    }, [jwtToken, navigate]);
    const handleClick=(m)=>{
        navigate(`/schedule/${m.id}`)
    }
    return(
        <Row>
            {models && models?.map((m,index)=>(
                <Card style={{ width: '14rem', marginLeft: "10px", backgroundColor: "ghostwhite", marginTop:"10px" }}>
                    <Link to={`/admin/model/${m.id}`}>
                        <Card.Img variant="top" src={m.image} style={{width: "100%", height: "250px", marginTop: "10px"}} />
                    </Link>
                    <Card.Body>
                        <Card.Title style={{textAlign: "center", color: "rgb(0, 175, 240)"}}>{m.first_name} {m.last_name}</Card.Title>
                        <Card.Text>
                            <Row>
                                    <Button variant={"outline-info"}  size="sm" onClick={()=>handleClick(m)}>Schedule</Button>
                                    <Button variant={"outline-info"}  size="sm" onClick={()=>handleShow(m)}>
                                        Subreddits
                                    </Button>
                            </Row>
                        </Card.Text>
                    </Card.Body>
                </Card>
            ))}
            {model &&
                <Modal data={model}  show={show} setShow={setShow}/>
            }
        </Row>


    )
}
export default Models;