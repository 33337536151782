import Input from "./form/Input";
import React, {useState} from "react";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import Checkbox from "./form/Checkbox";

function Subreddit(){
    const {id}=useParams();
    const {jwtToken}=useOutletContext()
    const [name, setName] = useState('');
    const [category, setCategory]=useState('')
    const [requirements, setRequirements] = useState('');
    const [ofLink, setOfLink] = useState(false);
    const navigate=useNavigate()

    const handleRequirements=(event)=>{
        setRequirements(event.target.value)
    }
    const handleName=(event)=>{
        setName(event.target.value)
    }
    const handleCategory=(event)=>{
        setCategory(event.target.value)
    }
    const [error, setError] = useState(null);
    const [errors, setErrors] = useState([]);

    const hasError = (key) => {
        return errors.indexOf(key) !== -1;
    };

    const handleSubmit=()=>{
        let errors = [];
        let required = [
            { field: category, name: "category" },
            { field: name, name: "subreddit_name" },
            { field: requirements, name: "requirements" },
            { field: ofLink, name: "ofLink" },
        ];

        required.forEach(function (obj) {
            if (obj.field === "") {
                errors.push(obj.name);
            }
        });
        setErrors(errors);
        if (errors.length > 0) {
            return false;
        }

        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "Bearer " + jwtToken);

        const requestBody = {
            name,
            requirements,
            category,
            ofLink,
        };

        const requestOptions = {
            body: JSON.stringify(requestBody),
            method: "POST",
            headers: headers,
        }

        fetch(`${process.env.REACT_APP_BACKEND}/admin/subreddit`,requestOptions)
            .then(response=>response.json())
            .then(data=>console.log(data))
            .catch(error=>console.log(error))
        setRequirements('')
        setName('')
        setCategory('')
        setOfLink(false)
    }

    return(
        <div>
            <h3>Subreddit</h3>
            <div style={{color:"white",backgroundColor: 'blue', borderRadius: "100000px", textAlign: "center"}}>
            <p>The name is the name of the subreddit</p>
            <p>the requirements field is mostly for commenting, in future well use it to determine what is needed for that subreddit</p>
            </div>

        <Input
            title={"Subreddit Name"}
            className={"form-control"}
            type={"text"}
            name={"subreddit_name"}
            value={name}
            onChange={handleName}
            errorDiv={hasError("subreddit_name") ? "text-danger" : "d-none"}
            errorMsg={"Please enter a subreddit name"}
        />
    <Input
        title={"Requirements"}
        className={"form-control"}
        type={"text"}
        name={"requirements"}
        value={requirements}
        onChange={handleRequirements}
        errorDiv={hasError("requirements") ? "text-danger" : "d-none"}
        errorMsg={"Please enter requirements"}
    />
    <Input
        title={"Category"}
        className={"form-control"}
        type={"text"}
        name={"category"}
        value={category}
        onChange={handleCategory}
        errorDiv={hasError("category") ? "text-danger" : "d-none"}
        errorMsg={"Please enter category"}
    />

        <Checkbox
            title={"Include OF Link"}
            name={"ofLink"}
            id={ofLink}
            onChange={(()=>setOfLink(!ofLink))}
            value={ofLink}
            checked={ofLink}
        />
            <button className={"btn btn-primary"} style={{marginTop: "10px"}} onClick={handleSubmit}>Add Subreddit</button>
            </div>
    )
}
export default Subreddit;