
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css"
import Modal from "react-bootstrap/Modal";
import Input from "./form/Input";
import Select from "./form/Select";
import Button from "react-bootstrap/Button";
const localizer = momentLocalizer(moment)

function Schedule({data}){
    let { id } = useParams();
    const {jwtToken}=useOutletContext()
    const navigate=useNavigate()
    const [show, setShow] = useState(false);
    const [showEvent, setShowEvent] = useState(false);
    const [cancel, setCancel] = useState(false);
    const handleClose = () => setShow(false);
    const handleCancel = () => setShowEvent(false);
    const handleShow = () => setShow(true);
    const [myEvents, setEvents] = useState([])
    const [url, setUrl] = useState('');
    const [caption, setCaption] = useState('');
    const [type, setType] = useState();
    const [error, setError] = useState(null);
    const [errors, setErrors] = useState([]);
    const [schedule, setSchedule] = useState();
    const [newEvent, setNewEvent] = useState();
    const [deleteId, setDeleteId] = useState();
    const [scheduleItem, setScheduleItem] = useState();

    const [idk, setIdk]=useState(true)

    const hasError = (key) => {
        return errors.indexOf(key) !== -1;
    };
    const handleSubmit=()=>{
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "Bearer " + jwtToken);
        const requestBody={
            action_type: "post",
            imgur_link: url,
            caption,
            scheduled_dt: schedule,
            model_id: +id,
            method: type,
        }

        let requestOptions = {
            body: JSON.stringify(requestBody),
            method: "POST",
            headers: headers,
            credentials: "include",
        };
        fetch(`${process.env.REACT_APP_BACKEND}/admin/model/${id}/schedule`,requestOptions)
            .then(response=>response.json())
            .then(data=>setNewEvent(data))
            .catch(error=>console.log(error))
        setShow(false)
    }
    const handleUrl=(event)=>{
        setUrl(event.target.value)
    }
    const handleCaption=(event)=>{
        setCaption(event.target.value)
    }
    const handleType=(event)=>{
        setType(event.target.value)
    }
    const handleDelete=(event)=>{
        const calEvent = scheduleItem;
        console.log(calEvent)
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "Bearer " + jwtToken);
        const requestOptions = {
            method: "DELETE",
            headers: headers,
        }

        fetch(`${process.env.REACT_APP_BACKEND}/admin/model/${id}/schedule/${calEvent.id}`, requestOptions)
            .then(response=>console.log(response))
            .catch(error=>console.log(error))

        setEvents(myEvents.filter(e=>e.id!==calEvent.id))
        setCancel(true)
        setShowEvent(false)
    }
    useEffect(()=>{
        if(jwtToken===''){
            navigate(`/login`)
        }
        //get events
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "Bearer " + jwtToken);

        const requestOptions = {
            method: "GET",
            headers: headers,
        }
        fetch(`${process.env.REACT_APP_BACKEND}/admin/model/${id}/schedule`,requestOptions)
            .then(response=>response.json())
            .then(data=>{
                console.log(data)
                const now= data.map(m=> (
                    {
                        id: m.reddit_action_id,
                        title: m.caption,
                        allDay: false,
                        start: new Date(m.scheduled_dt),
                        end: new Date(m.scheduled_dt),
                    }))
                setEvents(now)
            })
            .catch(error=>console.log(error))
        console.log(myEvents)
        setCancel(false)
    },[])


    const handleSelectSlot = useCallback(
        ({ start, end, caption }) => {
            setShow(true)
            setSchedule(start)
            if (idk) {
                console.log(newEvent)
                end=start
                setEvents((prev) => [...prev, { start, end, title: caption }])
            }
        },
        [setEvents]
    )

    const handleSelectEvent = useCallback(
        (calEvent) => {
            console.log(calEvent)
            setShowEvent(true)
            setScheduleItem(calEvent);
        },
        []  //cancel]
    )

    const { defaultDate, scrollToTime } = useMemo(
        () => ({
            defaultDate: new Date(2015, 3, 12),
            scrollToTime: new Date(1970, 1, 1, 6),
        }),
        []
    )
    return (
        <>
        <Calendar
            localizer={localizer}
            startAccessor="start"
            endAccessor="end"
            events={myEvents}
            onSelectEvent={handleSelectEvent}
            onSelectSlot={handleSelectSlot}
            selectable
            scrollToTime={scrollToTime}
            style={{ height: 500 }}
        />
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Input
                title={"Caption"}
                className={"form-control"}
                type={"text"}
                name={"caption"}
                value={caption}
                onChange={handleCaption}
                errorDiv={hasError("caption") ? "text-danger" : "d-none"}
                errorMsg={"Please enter a caption"}
            />
            <Input
                title={"Image URL"}
                className={"form-control"}
                type={"text"}
                name={"url"}
                value={url}
                onChange={handleUrl}
                errorDiv={hasError("url") ? "text-danger" : "d-none"}
                errorMsg={"Please enter a url"}
            />

        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
                Blast Me!
            </Button>
        </Modal.Footer>
    </Modal>
    <Modal show={showEvent} onHide={handleCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Post</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Delete
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCancel}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
</>
    )
}
export default Schedule;
let eventGuid = 0
let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today
export function createEventId() {
    return String(eventGuid++)
}
//    <Paper>
//         <Scheduler
//             data={data}
//             height={660}
//         >
//             <ViewState
//                 currentDate={currentDate}
//                 onCurrentDateChange={currentDateChange}
//
//             />
//             <EditingState
//             onCommitChanges={commitChanges}
//         />
//             <MonthView
//                 startDayHour={9}
//                 endDayHour={14}
//             />
//             <Toolbar />
//             <DateNavigator />
//             <TodayButton />
//             <EditRecurrenceMenu />
//             <ConfirmationDialog />
//             <Appointments />
//             <AppointmentTooltip
//             showCloseButton
//             showOpenButton
//         />
//             <AppointmentForm
//             />
//         </Scheduler>
//     </Paper>