import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import Home from './components/Home';
import ErrorPage from "./components/ErrorPage";
import Login from "./components/Login";
import Models from "./components/Models";
import EditModel from "./components/EditModel";
import Schedule from "./components/Schedule";
import Subreddit from "./components/Subreddit";


const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {index: true, element: <Home /> },
            {
                path: "/admin/models",
                element: <Models/>,
            },
            {
                path: "/admin/model/:id",
                element: <EditModel/>,
            },
            {
                path: "/admin/model/0",
                element: <EditModel/>,
            },
            {
                path: "/login",
                element: <Login />,
            },
            {
                path:"/schedule/:id",
                element: <Schedule />,
            },
            {
                path:"/admin/subreddit",
                element: <Subreddit />,
            },
        ]
    }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
