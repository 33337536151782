import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import Input from "./form/Input";
import Select from "./form/Select";
import TextArea from "./form/TextArea";
import Swal from "sweetalert2";

const EditModel = () => {
    const navigate = useNavigate();
    const { jwtToken } = useOutletContext();

    const [error, setError] = useState(null);
    const [errors, setErrors] = useState([]);

    const hasError = (key) => {
        return errors.indexOf(key) !== -1;
    };

    const [model, setModel] = useState({
        id:"",
        first_name: "",
        last_name: "",
        email: "",
        reddit_proxy: {},
        anty_dolphin: {},
        bright_data_proxy_id: "",
        reddit_client_id: "",
        reddit_client_secret: "",
        reddit_client_username: "",
        reddit_client_password: "",
        image: "",
        OnlyfansLink: "",
    });

    // get id from the URL
    let { id } = useParams();
    if (id === undefined) {
        id = 0;
    }

    useEffect(() => {
        if (jwtToken === "") {
            navigate("/login");
            return;
        }
        if (id === 0) {
            // adding a model
            setModel({
                id: 0,
                first_name: "",
                last_name: "",
                email:"",
                bright_data_proxy_id: 1,
                anty_dolphin: {},
                reddit_proxy: {},
                reddit_client_id: "",
                reddit_client_secret: "",
                reddit_client_username: "",
                reddit_client_password: "",
                image: "",
                OnlyfansLink: "",
            });

        } else {
            // editing an existing model
            const headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("Authorization", "Bearer " + jwtToken);

            const requestOptions = {
                method: "GET",
                headers: headers,
            };

            fetch(`${process.env.REACT_APP_BACKEND}/admin/model/${id}`, requestOptions)
                .then((response) => {
                    if (response.status !== 200) {
                        setError("Invalid response code: " + response.status);
                    }
                    return response.json();
                })
                .then((data) => {
                    // set state
                    console.log(data)
                    setModel(
                        data.model
                    );
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [id, jwtToken, navigate]);

    const handleSubmit = (event) => {
        event.preventDefault();
        let errors = [];
        let required = [
            { field: model.first_name, name: "first_name" },
            { field: model.last_name, name: "last_name" },
            { field: model.email, name: "email" },
            { field: model.reddit_client_id, name: "reddit_client_id" },
            { field: model.reddit_client_secret, name: "reddit_client_secret" },
            { field: model.reddit_client_username, name: "reddit_client_username" },
            { field: model.reddit_client_password, name: "reddit_client_password" },
            { field: model.image, name: "image" },
            { field: model.OnlyfansLink, name: "OnlyfansLink"},
        ];

        required.forEach(function (obj) {
            if (obj.field === "") {
                errors.push(obj.name);
            }
        });
        setErrors(errors);
        console.log(errors)

        if (errors.length > 0) {
            return false;
        }

        // passed validation, so save changes
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "Bearer " + jwtToken);

        // assume we are adding a new model
        let method = "PUT";

        if (model.id > 0) {
            method = "PATCH";
        }
        console.log(model)

        const requestBody = model;


        let requestOptions = {
            body: JSON.stringify(requestBody),
            method: method,
            headers: headers,
            credentials: "include",
        };

        fetch(`${process.env.REACT_APP_BACKEND}/admin/model/${model.id}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    console.log(data.error);
                } else {
                    navigate("/admin/models");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleChange = () => (event) => {
        let value = event.target.value;
        let name = event.target.name;
        if (name==="bright_data_proxy_id"){
            console.log("here")
            value= +value
        }
        if (name==="anty_dolphin_id"){
            value= +value
        }
        console.log(model)
        setModel({
            ...model,
            [name]: value,
        });

    };



    const confirmDelete = () => {
        Swal.fire({
            title: 'Delete model?',
            text: "You cannot undo this action!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                let headers = new Headers();
                headers.append("Authorization", "Bearer " + jwtToken)

                const requestOptions = {
                    method: "DELETE",
                    headers: headers,
                }

                fetch(`${process.env.REACT_APP_BACKEND}/admin/model/${model.id}`, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            console.log(data.error);
                        } else {
                            navigate("/admin/models");
                        }
                    })
                    .catch(err => {console.log(err)});
            }
        })
    }

    if (error !== null) {
        return <div>Error: {error.message}</div>;
    } else {
        return (
            <div>
                <h2>Add/Edit Model</h2>
                <hr />
                {console.log(model)}
                {/* <pre>{JSON.stringify(movie, null, 3)}</pre> */}
                <form onSubmit={handleSubmit}>
                    <input type="hidden" name="id" value={model.id} id="id"></input>
                    <Input
                        title={"First Name"}
                        className={"form-control"}
                        type={"text"}
                        name={"first_name"}
                        value={model.first_name}
                        onChange={handleChange("first_name")}
                        errorDiv={hasError("first_name") ? "text-danger" : "d-none"}
                        errorMsg={"Please enter a First Name"}
                    />
                    <Input
                        title={"Last Name"}
                        className={"form-control"}
                        type={"text"}
                        name={"last_name"}
                        value={model.last_name}
                        onChange={handleChange("last_name")}
                        errorDiv={hasError("last_name") ? "text-danger" : "d-none"}
                        errorMsg={"Please enter a Last Name"}
                    />
                    <Input
                        title={"Email"}
                        className={"form-control"}
                        type={"text"}
                        name={"email"}
                        value={model.email}
                        onChange={handleChange("email")}
                        errorDiv={hasError("email") ? "text-danger" : "d-none"}
                        errorMsg={"Please enter a email"}
                    />
                    <Input
                        title={"Reddit Client Id"}
                        className={"form-control"}
                        type={"text"}
                        name={"reddit_client_id"}
                        value={model.reddit_client_id}
                        onChange={handleChange("reddit_client_id")}
                        errorDiv={hasError("reddit_client_id") ? "text-danger" : "d-none"}
                        errorMsg={"Please enter a Reddit Client Id"}
                    />
                 <Input
                        title={"Reddit Client Secret"}
                        className={"form-control"}
                        type={"text"}
                        name={"reddit_client_secret"}
                        value={model.reddit_client_secret}
                        onChange={handleChange("reddit_client_secret")}
                        errorDiv={hasError("reddit_client_secret") ? "text-danger" : "d-none"}
                        errorMsg={"Please enter a reddit_client_secret"}
                    />
                    <Input
                        title={"Reddit Client Username"}
                        className={"form-control"}
                        type={"text"}
                        name={"reddit_client_username"}
                        value={model.reddit_client_username}
                        onChange={handleChange("reddit_client_username")}
                        errorDiv={hasError("reddit_client_username") ? "text-danger" : "d-none"}
                        errorMsg={"Please enter a reddit_client_username"}
                    />
                    <Input
                        title={"Reddit Client Password"}
                        className={"form-control"}
                        type={"text"}
                        name={"reddit_client_password"}
                        value={model.reddit_client_password}
                        onChange={handleChange("reddit_client_password")}
                        errorDiv={hasError("reddit_client_password") ? "text-danger" : "d-none"}
                        errorMsg={"Please enter a reddit_client_password"}
                    />
                    <Input
                        title={"Image"}
                        className={"form-control"}
                        type={"text"}
                        name={"image"}
                        value={model.image}
                        onChange={handleChange("image")}
                        errorDiv={hasError("image") ? "text-danger" : "d-none"}
                        errorMsg={"Please enter a image"}
                    />
                    <Input
                        title={"OnlyFans Link"}
                        className={"form-control"}
                        type={"text"}
                        name={"OnlyfansLink"}
                        value={model.OnlyfansLink}
                        onChange={handleChange("OnlyfansLink")}
                        errorDiv={hasError("OnlyfansLink") ? "text-danger" : "d-none"}
                        errorMsg={"Please enter a OnlyfansLink link"}
                    />
                    <hr />

                    <hr />

                    <button className="btn btn-primary">Save</button>

                    {model.id > 0 && (
                        <a href="#!" className="btn btn-danger ms-2" onClick={confirmDelete}>
                            Delete Model
                        </a>
                    )}
                </form>
            </div>
        );
    }
};

export default EditModel;
